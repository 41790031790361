<template>
  <div class="container">
    <div class="classification-location">
      <div>您的当前位置:</div>
      <div @click="to('/')">首页</div>
      <div>|</div>
      <div>帮助中心</div>
    </div>
    <img
      class="about-img"
      src="http://shop.shangjiu-gift.com.cn/Uploads/201902/6365576_92d314.jpg"
      alt=""
    />
    <div class="main">
      <div class="sliderbar">
        <div class="sliderbar-title">企业简介</div>
        <div class="pro-sublist">
          <div
            :class="{ active: index == briefActive }"
            v-for="(item, index) in briefList"
            :key="index"
            @click="briefChange(index)"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <div class="contentp">
        <div class="crumbs">
          <div class="el-icon-s-home"></div>
          <div>></div>
          <div>{{ briefList[briefActive] }}</div>
        </div>
        <div class="help_contain">
          <div class="help_contain-title">{{ briefList[briefActive] }}</div>
          <div v-if="briefActive == 0"></div>
          <div v-if="briefActive == 1"></div>
          <div class="relation" v-if="briefActive == 2"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "aboutUs",
  data() {
    return {
      briefList: ["礼品资讯", "关于我们", "联系我们", "会员权益"],
      briefActive: 1,
    };
  },
  methods: {
    briefChange(index) {
      if (index == 0) {
        this.$router.push("/present");
      } else {
        this.briefActive = index;
      }
    },
    to(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 1200px;
  margin: 0 auto;
  .about-img {
    height: 90px;
  }
  .classification-location {
    display: flex;
    font-size: 11px;
    margin: 23px 0 8px;
    border-bottom: 1px solid #c51323;
    padding-bottom: 9px;
    div {
      &:nth-child(2) {
        margin-left: 10px;
        cursor: pointer;
      }
      &:nth-child(3) {
        margin: 0 10px;
      }
      &:nth-child(4) {
        color: #757373;
      }
    }
  }
  .main {
    margin-top: 20px;
    display: flex;
    .sliderbar {
      width: 220px;
      background: #fff;
      padding: 5px 12px;
      .sliderbar-title {
        background: #c51323;
        color: #fff;
        font-weight: bold;
        padding: 4px 0;
        padding-left: 10px;
        font-size: 18px;
      }
      .pro-sublist {
        font-size: 12px;
        border-left: 2px solid #ddd;
        margin-left: 20px;
        padding-left: 20px;
        margin-top: 10px;
        div {
          margin-bottom: 10px;
          cursor: pointer;
          color: #6b7255;
          &.active {
            color: #000;
            font-weight: bold;
          }
        }
      }
    }
    .contentp {
      flex: 1;
      margin-left: 30px;
      .crumbs {
        display: flex;
        align-items: center;
        div {
          &:first-child {
            cursor: pointer;
          }
          &:nth-child(2) {
            margin: 0 5px;
            color: #c51323;
          }
          &:last-child {
            font-size: 12px;
            color: #c51323;
            font-weight: bold;
          }
        }
      }
      .help_contain {
        border-top: 3px #c51323 solid;
        background: #fff;
        padding: 10px;
        .help_contain-title {
          text-align: center;
          font-weight: bold;
          font-size: 20px;
        }
        .relation {
        }
      }
    }
  }
}
</style>
